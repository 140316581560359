<template>

    <div>
        <b-row>
            <b-col md="12">
                <h4>
                    <b-avatar size="30px" variant="light-info" rounded="">
                        <feather-icon icon="UserCheckIcon" size="20" />
                    </b-avatar>
                    Presensi Hari Ini
                </h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="2" sm="4" class="my-1">
                <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50"> </label>
                    <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
                </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
                <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect"
                    class="mb-0">
                    <b-input-group size="sm">
                        <b-form-select id="sortBySelect" v-model="setid_bidang" :options="bidang" class="w-75"
                            :label="bidang.nama_bidang" @input="load_presensi()">
                            <template v-slot:first>
                                <option value="">
                                    -- Bidang --
                                </option>
                            </template>
                        </b-form-select>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
                <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm"
                    label-for="filterInput" class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">
                                Clear
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <b-col cols="12">
                <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                    :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                    :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                    <template #cell(rs_data)="data">
                        <!-- {{ data.item }} -->
                        <b-row>
                            <b-col md="1">
                                <b-avatar rounded="" size="70px" :src="data.item.foto" />
                            </b-col>
                            <b-col md="3">
                                <small>Nama</small>
                                <h6>{{ data.item.nm_lengkap }}</h6>
                                <small>No Hp</small>
                                <h6><a :href="'https://api.whatsapp.com/send?phone='+data.item.no_hp " target="_blank">{{ data.item.no_hp }}</a> 
                                     </h6>
                            </b-col>
                            <b-col md="3">
                                <small>Kelompok</small>
                                <h6>{{ data.item.nama_kelompok }}</h6>
                                <small>Bidang</small>
                                <h6>{{ data.item.nama_bidang }}</h6>
                            </b-col>
                            <b-col md="2">
                                <small>Jam Masuk</small>
                                <h6>{{ data.item.jam_masuk }}
                                    <a :href="'https://www.google.com/maps/dir/'+data.item.kordinat_masuk" target="_blank" v-show=" data.item.jam_masuk !=''">
                                        <feather-icon icon="MapPinIcon" size="20" />
                                    </a>
                                     
                                </h6>
                                <small>Jam Pulang</small>
                                <h6>{{ data.item.jam_pulang }}
                                    <a :href="'https://www.google.com/maps/dir/'+data.item.kordinat_pulang" target="_blank" 
                                     v-show=" data.item.jam_pulang != null ">
                                        <feather-icon icon="MapPinIcon" size="20" />
                                    </a>
                                </h6>
                            </b-col>
                            <b-col md="3">
                                <b-row>
                                    <b-col md="6" >
                                        <small>Foto Masuk</small><br>
                                        <b-avatar rounded="" size="100px" :src="data.item.foto_masuk" />
                                    </b-col>
                                    <b-col md="6"  v-show=" data.item.jam_pulang != null ">
                                        <small>Foto Pulang</small><br>
                                        <b-avatar rounded="" size="100px" :src="data.item.foto_pulang" />
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>
                    </template>

                </b-table>
            </b-col>
            <b-col cols="6">
                Total Record <b> {{ items.length }}</b>
            </b-col>
            <b-col cols="6">
                <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right"
                    size="sm" class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
        },
        props: {
            items: {},
            bidang: {},
            id_bidang: {}
        },
        data() {
            return {
                perPage: 3,
                pageOptions: [3, 5, 10, 100, 1000],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                fields: [{
                        key: 'rs_data',
                        label: '',
                    },

                ],
            }
        },
        computed: {
            setid_bidang: {
                get: function () {
                    return this.id_bidang;
                },
                set: function (newValue) {
                    return this.id_bidang = newValue;
                },
            },
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        emits: ["load_presensi"],
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {
            load_presensi() {
                this.$emit("load_presensi", this.id_bidang)
            },
            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`
                this.infoModal.content = JSON.stringify(item, null, 2)
                this.$root.$emit('bv::show::modal', this.infoModal.id, button)
            },
            resetInfoModal() {
                this.infoModal.title = ''
                this.infoModal.content = ''
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>