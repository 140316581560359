<template>
    <div>
        <div class="card"> 
            <div class="  statistics-body">
                <div class="row p-1">
                    <div class="col-xl-4 col-sm-6 col-12 " @click="get_data('presensi_hari_ini')">
                        <div class="media">
                            <b-avatar size="50px" variant="light-info"   >
                                <feather-icon icon="UserCheckIcon" size="20" />
                            </b-avatar> 
                            <div class="media-body my-auto ml-1">
                                <h4 class="font-weight-bolder mb-0">{{ statistik.aktif_hari_ini}}</h4>
                                <p class="card-text font-small-3 mb-0">Presensi Hari ini</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-sm-6 col-12 " @click="get_data('tidak_presensi')">
                        <div class="media" >
                            <b-avatar size="50px" variant="light-danger"   >
                                <feather-icon icon="UserXIcon" size="20" />
                            </b-avatar>  
                            <div class="media-body my-auto ml-1">
                                <h4 class="font-weight-bolder mb-0">{{ parseInt(statistik.user_aktif) - parseInt(statistik.aktif_hari_ini)}}</h4>
                                <p class="card-text font-small-3 mb-0">Tidak Presensi</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-sm-0" @click="get_data('all')">
                        <div class="media">
                            <b-avatar size="50px" variant="light-success"   >
                                <feather-icon icon="UsersIcon" size="20" />
                            </b-avatar>   
                            <div class="media-body my-auto ml-1">
                                <h4 class="font-weight-bolder mb-0">{{ statistik.user_aktif}}</h4>
                                <p class="card-text font-small-3 mb-0">Total User</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {
        BAvatar
    } from 'bootstrap-vue'

    export default {
        components: {
            BAvatar
        },
        props :{
            statistik : {}
        },
        emits:["get_data"],
        methods:{
            get_data(valu){
                this.$emit('get_data', valu)
            }
        }

    }
</script>

<style>

</style>