<template>
  <div>
    <presensi :statistik=dataStatstik @get_data=get_data></presensi>
    <b-row>
      <b-col md="12"> 
        <b-card v-if="st_view_data =='presensi_hari_ini'">
          <berangkat-vue :items = rs_presensi :bidang = bidang @load_presensi = load_presensi  :id_bidang = id_bidang></berangkat-vue>
        </b-card>
        <b-card  v-if="st_view_data !='presensi_hari_ini'">
          <tidakPresensi  :items = rs_tidak_presensi :bidang = bidang @load_tidak_presensi = load_tidak_presensi  :id_bidang = id_bidang></tidakPresensi>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>


<script>
  import axios from '@/config/Axios';
  import Base from '@/config/Mixins_base';
  import presensi from './statistik';
  import tidakPresensi from './tidakPresensi.vue';
  import {
    BRow,
    BCol,
    BButton,
    BCard
  } from 'bootstrap-vue'
  import berangkatVue from './berangkat.vue';

  export default {
    components: {
      BRow,
      BCol,
      BButton,
      BCard,
      presensi,
      berangkatVue,
      tidakPresensi
    },
    mixins: [Base],
    data() {
      return {
        dataStatstik: {},
        mapsData: [],
        bidang: [],
        rs_presensi: [],
        rs_tidak_presensi : [],
        st_view_data : 'presensi_hari_ini',
        id_bidang :''
      }
    },
    mounted() {
      this.cek_token();
      this.load_statistik();
      this.get_bidang()
      this.get_presensi()

    },
    methods: {
      get_data(val) {
        this.st_view_data= val;
        this.id_bidang ='';
        if(val =='presensi_hari_ini'){
          this.get_presensi() 
        }else{
          this.get_tidak_presensi()
        } 
        // alert(this.id_bidang)
      },
      load_presensi(val){
        this.id_bidang = val;
        this.get_presensi()
      },

      load_tidak_presensi(val){
        this.id_bidang = val;
        this.get_tidak_presensi()
      },
      async load_statistik() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/dashboard/statistik/load_data',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.dataStatstik = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async get_bidang() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/rekap_presensi/bidang',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.bidang = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async get_presensi() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/rekap_presensi/presensi',
            data: {
              id_bidang : self.id_bidang
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.rs_presensi = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async get_tidak_presensi() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/rekap_presensi/tidak_presensi',
            data: {
              id_bidang : self.id_bidang
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.rs_tidak_presensi = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    }

  }
</script>
<style>

</style>